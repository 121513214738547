import React from "react";
import "./style.less";

const ListItem = (props) => {
  //console.log("==>", props.fournotfonund);

  if (props.gif && props.mobile) {
    return (
      <div className="swiper-slide">
        <div className="d-f f-c">
          <div className="videoContainer">
            <img src={props.gif} className="img-fluid" />
          </div>
          <div>
            <div className="itemTitle">{props.title}</div>
            <div className="itemDesc">{props.desc}</div>
            {props.desc2 && <div className="itemDesc">{props.desc2}</div>}
            {props.desc3 && <div className="itemDesc">{props.desc3}</div>}
            {props.desc4 && <div className="itemDesc">{props.desc4}</div>}
          </div>
        </div>
      </div>
    );
  }

  if (props.right) {
    return (
      <div
        className={`itemContainer d-f ${props.selected}  right`}
        style={{
          // width: "443px",
          height: "77.77px",
          borderRadius: "12px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        <div className="itemTitle">{props.title}</div>
        <div className="itemDesc">{props.desc}</div>
        {props.desc2 && <div className="itemDesc">{props.desc2}</div>}
        {props.desc3 && <div className="itemDesc">{props.desc3}</div>}
        {props.desc4 && <div className="itemDesc">{props.desc4}</div>}
      </div>
    );
  }

  return (
    <div
      className={`itemContainer d-f ${props.selected} ${
        props.right ? "right" : "left"
      }`}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      style={{
        // width: "443px",
        height: "77.77px",
        borderRadius: "12px",
        alignItems: "center",
      }}
    >
      <div className="itemTitle">{props.title}</div>
      {/* <div className='itemDesc'>{props.desc}</div> */}
      {/* {props.desc2 && <div className="itemDesc">{props.desc2}</div>} */}
      {props.desc3 && <div className="itemDesc">{props.desc3}</div>}
      {props.desc4 && <div className="itemDesc">{props.desc4}</div>}
    </div>
  );
};

export default React.memo(ListItem);
