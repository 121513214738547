import "./App.less";
import "./common.less";
import Header from "./Component/Header/Header";
import DescBgSec1 from "./images/sec1/bgright.png";

import mbDescBgSec1 from "./images/sec1/bgrightmb.png";

import WOW from "wowjs";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "./Component/Button/Button";
// import Sec1ARightImg from "./images/sec1/rightImg.png";
import Sec2Img from "./images/sec2/sec2.gif";
import ListItem from "./Component/ListItem/ListItem";

import Sec10Img from "./images/sec10/rightimg.png";
import Sec10Img2 from "./images/sec10/sec10-2.png";
import Sec10Img3 from "./images/sec10/sec10-3.png";
import { useState, useEffect, useRef } from "react";
import logo from "./images/logo.png";

import ServicesItem from "./Component/ServicesItem/ServicesItem";
// import linkedin from "./images/linkedin.png";
// import twitter from "./images/twitter.png";

import icon1 from "./images/sec11/icon1.png";
import icon2 from "./images/sec11/icon2.png";
import icon3 from "./images/sec11/icon3.png";
import icon4 from "./images/sec11/icon4.png";
import icon5 from "./images/sec11/icon5.png";
import icon6 from "./images/sec11/icon6.png";
import icon7 from "./images/sec11/icon7.png";

/** Web dashboard **/
import wd1 from "./images/new/01.png";
import wd2 from "./images/new/02.png";
import wd3 from "./images/new/03.png";
import wd4 from "./images/new/04.png";

/** User App **/
import ua1 from "./images/new/user1.png";
import ua2 from "./images/new/user2.png";
import ua3 from "./images/new/user3.png";
import ua4 from "./images/new/user4.png";

/** Admin App **/
import aa1 from "./images/new/Admin1.png";
import aa2 from "./images/new/Admin2.png";
import aa3 from "./images/new/Admin3.png";
import aa4 from "./images/new/Admin4.png";

/** Squad App **/
import sa1 from "./images/new/SQUAD1.png";
import sa2 from "./images/new/SQUAD2.png";
import sa3 from "./images/new/SQUAD3.png";
import sa4 from "./images/new/SQUAD4.png";

/** reduce/increase & email svgs **/
import increase from "./images/new/increase.svg";
import decrease from "./images/new/decrease.svg";
import emailicon from "./images/new/emailicon.svg";

import db1 from "./images/sec3/DB-1.webm";
import db2 from "./images/sec3/DB-2.webm";
import db3 from "./images/sec3/DB-3.webm";
import db4 from "./images/sec3/DB-4.webm";

import db1mp4 from "./images/sec3/DB-1.mp4";
import db2mp4 from "./images/sec3/DB-2.mp4";
import db3mp4 from "./images/sec3/DB-3.mp4";
import db4mp4 from "./images/sec3/DB-4.mp4";

import db1gif from "./images/sec3/DB-1.gif";
import db2gif from "./images/sec3/DB-2.gif";
import db3gif from "./images/sec3/DB-3.gif";
import db4gif from "./images/sec3/DB-4.gif";

import squad1 from "./images/sec5/squad1.webm";
import squad2 from "./images/sec5/squad2.webm";

import squad1mp4 from "./images/sec5/squad1.mp4";
import squad2mp4 from "./images/sec5/squad2.mp4";

import squad1gif from "./images/sec5/squad1.gif";
import squad2gif from "./images/sec5/squad2.gif";

import user1 from "./images/sec7/user1.webm";
import user2 from "./images/sec7/user2.webm";
import user3 from "./images/sec7/user3.webm";
import user4 from "./images/sec7/user4.webm";

import user1mp4 from "./images/sec7/user1.mp4";
import user2mp4 from "./images/sec7/user2.mp4";
import user3mp4 from "./images/sec7/user3.mp4";
import user4mp4 from "./images/sec7/user4.mp4";

import user1gif from "./images/sec7/user1.gif";
import user2gif from "./images/sec7/user2.gif";
import user3gif from "./images/sec7/user3.gif";
import user4gif from "./images/sec7/user4.gif";

import admin1 from "./images/sec8/admin1.webm";
import admin2 from "./images/sec8/admin2.webm";
import admin3 from "./images/sec8/admin3.webm";
import admin4 from "./images/sec8/admin4.webm";

import admin1mp4 from "./images/sec8/admin1.mp4";
import admin2mp4 from "./images/sec8/admin2.mp4";
import admin3mp4 from "./images/sec8/admin3.mp4";
import admin4mp4 from "./images/sec8/admin4.mp4";

import admin1gif from "./images/sec8/admin1.gif";
import admin2gif from "./images/sec8/admin2.gif";
import admin3gif from "./images/sec8/admin3.gif";
import admin4gif from "./images/sec8/admin4.gif";

import sec4 from "./images/sec4/sec4.mp4";
import sec4gif from "./images/sec4/sec4.gif";

// import fb from "./images/fb.png";
import fb from "./images/Facebook.svg";
import instagram from "./images/Instagram.svg";
import twitter from "./images/Twitter.svg";
import linkedin from "./images/Linkedin.svg";
// import instagram from "./images/instagram.png";

import lowest from "./images/sec6/lowest.png";
import privacy from "./images/sec6/privacy.png";
import network from "./images/sec6/network.png";

import sec91 from "./images/sec9/1.png";
import sec92 from "./images/sec9/2.png";
import sec93 from "./images/sec9/3.png";
import sec94 from "./images/sec9/4.png";
import "swiper/swiper-bundle.css";
import Swiper, { Navigation, Pagination, Controller, Autoplay } from "swiper";

import useWindowDimensions from "./useWindowDimensions";
import ModelForm from "./Component/Model/Model";
Swiper.use([Navigation, Controller, Pagination, Autoplay]);
const sec1ListArr = [
  {
    title: "User management",
    // desc: "Easily onboard and manage your customers",
    src: db1,
    src2: db1mp4,
    gif: db1gif,
  },
  {
    title: "First and last mile management",
    // desc: "Process shipments - Mid Mile and 3PL processing",
    src: db2,
    src2: db2mp4,
    gif: db2gif,
  },
  {
    title: "Reports and analytics",
    // desc: "Generate carrier AWB using integrations",
    src: db3,
    src2: db3mp4,
    gif: db3gif,
  },
  {
    title: "Billing and invoicing",
    // desc: "Seamlessly assign pickups and deliveries ",
    // desc2: "Get daily driver reports and analytics",
    src: db4,
    src2: db4mp4,
    gif: db4gif,
  },
];

const sec2ListArr = [
  {
    title: "Route optimization",
    // desc: "Find the most optimized route   ",
    // desc2: "Efficiently assign pickups and deliveries",
    // desc3: "Geolocation",
    // desc4: "Save on fuel costs and delivery time",
    src: squad1,
    src2: squad1mp4,
    gif: squad1gif,
  },
  {
    title: "Geolocation",
    // desc: "Driver mapping ",
    // desc2: "Notification to customers",
    // desc3: "Geolocation",
    src: squad2,
    src2: squad2mp4,
    gif: squad2gif,
  },
  {
    title: "Driver mapping",
    // desc: "Driver mapping ",
    // desc2: "Notification to customers",
    // desc3: "Geolocation",
    src: squad2,
    src2: squad2mp4,
    gif: squad2gif,
  },
  {
    title: "Save 30% of your fuel costs and delivery time",
    src: squad2,
    src2: squad2mp4,
    gif: squad2gif,
  },
];

const sec4ListArr = [
  {
    title: "Get quick rates",
    desc: "Your customers can check rates of their shipments",
    src: user1,
    src2: user1mp4,
    gif: user1gif,
  },
  {
    title: "Place orders",
    desc: "Your customers can place shipments on your App",
    src: user2,
    src2: user2mp4,
    gif: user2gif,
  },
  {
    title: "Track shipments",
    desc: "Your customers can track orders from your App",
    src: user3,
    src2: user3mp4,
    gif: user3gif,
  },
  {
    title: "Make payments",
    desc: "Your customers can view invoices and make payments on your App",
    src: user4,
    src2: user4mp4,
    gif: user4gif,
  },
];

const sec3ListArr = [
  {
    title: "In-scan & out-scan shipments",
    // desc: "In-scan and outscan shipments at the warehouse",
    src: admin1,
    src2: admin1mp4,
    gif: admin1gif,
  },
  {
    title: "Create carrier wise manifest reports",
    src: admin3,
    src2: admin3mp4,
    gif: admin1gif,
  },
  {
    title: "Edit & update shipments",
    // desc: "Update shipment details and tracking status",
    src: admin4,
    src2: admin4mp4,
    gif: admin1gif,
  },
  {
    title: "Generate carrier, own and masked labels ",
    src: admin4,
    src2: admin4mp4,
    gif: admin1gif,
  },
];

const arr1 = [
  { img: icon1 },
  { img: icon2 },
  { img: icon3 },

  { img: icon1 },
  { img: icon2 },
  { img: icon3 },

  { img: icon1 },
  { img: icon2 },
  { img: icon3 },
  { img: icon1 },
  { img: icon2 },
  { img: icon3 },

  { img: icon1 },
  { img: icon2 },
  { img: icon3 },

  { img: icon1 },
  { img: icon2 },
  { img: icon3 },
];
const arr2 = [
  { img: icon4 },
  { img: icon5 },

  { img: icon4 },
  { img: icon5 },

  { img: icon4 },
  { img: icon5 },
  { img: icon4 },
  { img: icon5 },

  { img: icon4 },
  { img: icon5 },

  { img: icon4 },
  { img: icon5 },

  { img: icon4 },
  { img: icon5 },

  { img: icon4 },
  { img: icon5 },

  { img: icon4 },
  { img: icon5 },
];
const arr3 = [
  { img: icon6 },
  { img: icon7 },
  { img: icon6 },
  { img: icon7 },
  { img: icon6 },
  { img: icon7 },
  { img: icon6 },
  { img: icon7 },
  { img: icon6 },
  { img: icon7 },
  { img: icon6 },
  { img: icon7 },
  { img: icon6 },
  { img: icon7 },
  { img: icon6 },
  { img: icon7 },
  { img: icon6 },
  { img: icon7 },
];

const merchantLogoList = arr1.map((item, i) => {
  return (
    <div key={i} className="slide slidemarginright">
      <div className="d-f" style={{ paddingTop: "2em", paddingRight: "3em" }}>
        <div>
          <img src={item.img} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  );
});

const merchantLogoList2 = arr2.map((item, i) => {
  return (
    <div key={i} className="slide slidemarginright">
      <div className="d-f" style={{ paddingTop: "2em", paddingRight: "3em" }}>
        <div>
          <img src={item.img} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  );
});

const merchantLogoList3 = arr3.map((item, i) => {
  return (
    <div key={i} className="slide slidemarginright">
      <div className="d-f" style={{ paddingTop: "2em", paddingRight: "3em" }}>
        <div>
          <img src={item.img} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  );
});

const App = () => {
  const { height, width } = useWindowDimensions();

  const [isShown, setShown] = useState(false);

  const [sec3HoverInd, setSec3HoverInd] = useState(0);
  const videoRef = useRef();
  const videoRef2 = useRef();
  const videoRef3 = useRef();
  const videoRef4 = useRef();

  const [sec5HoverInd, setSec5HoverInd] = useState(0);
  const videoRef5 = useRef();
  const videoRef6 = useRef();
  const [sec5Video, setSec5Video] = useState(squad1);

  const [sec7HoverInd, setSec7HoverInd] = useState(0);
  const [sec7Video, setSec7Video] = useState(user1);

  const [sec8HoverInd, setSec8HoverInd] = useState(0);
  const [sec8Video, setSec8Video] = useState(admin1);

  const videoRef8 = useRef();
  const videoRef9 = useRef();
  const videoRef10 = useRef();
  const videoRef11 = useRef();

  const videoRef12 = useRef();
  const videoRef13 = useRef();
  const videoRef14 = useRef();
  const videoRef15 = useRef();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

    const sec10swiper = new Swiper(".sec10-swiper", {
      slidesPerView: 1,
      loop: true,

      autoplay: {
        delay: 5000,
      },
      grabCursor: true,

      pagination: {
        el: ".sec10 .swiper-pagination",
        clickable: true,
      },
    });

    const mbsec3swiper = new Swiper(".mbsec3-swiper", {
      slidesPerView: 1,
      loop: true,

      autoplay: {
        delay: 5000,
      },
      grabCursor: true,

      pagination: {
        el: ".mbsec3-swiper .swiper-pagination",
        clickable: true,
      },
    });

    const mbsec5swiper = new Swiper(".mbsec5-swiper", {
      slidesPerView: 1,
      loop: true,

      autoplay: {
        delay: 5000,
      },
      grabCursor: true,

      pagination: {
        el: ".mbsec5-swiper .swiper-pagination",
        clickable: true,
      },
    });

    const mbsec8swiper = new Swiper(".mbsec8-swiper", {
      slidesPerView: 1,
      loop: true,

      autoplay: {
        delay: 5000,
      },
      grabCursor: true,

      pagination: {
        el: ".mbsec8-swiper .swiper-pagination",
        clickable: true,
      },
    });
  }, []);

  const sec1MouseEnter = (e, i) => {
    e.preventDefault();
    setSec3HoverInd(i);
    videoRef.current?.restart();
    videoRef2.current?.restart();
    videoRef3.current?.restart();
    videoRef4.current?.restart();
  };

  const sec2MouseEnter = (e, i) => {
    e.preventDefault();
    setSec5HoverInd(i);
    videoRef5.current?.restart();
    videoRef6.current?.restart();
  };

  const sec3MouseEnter = (e, i) => {
    e.preventDefault();
    setSec7HoverInd(i);

    videoRef8.current?.restart();
    videoRef9.current?.restart();
    videoRef10.current?.restart();
    videoRef11.current?.restart();
  };

  const sec8MouseEnter = (e, i) => {
    e.preventDefault();
    setSec8HoverInd(i);
    videoRef12.current?.restart();
    videoRef13.current?.restart();
    videoRef14.current?.restart();
    videoRef15.current?.restart();
  };

  const scrollToProduct = () => {
    const id = "prooduct";
    const yOffset = -70;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const scrollToHome = () => {
    const id = "home";
    const yOffset = -70;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const modelShow = () => {
    setShown(true);
  };

  return (
    <>
      <Header
        showModel={modelShow}
        homeBtnClick={scrollToHome}
        productBtnClick={scrollToProduct}
      />
      {/* <img className="bgDesc img-fluid" src={DescBgSec1} /> */}
      <section id="home" className="sec1">
        <Row style={{ margingTop: "78px" }}>
          <Col className="p-0" sm={12} xs={12} lg={6} md={6}>
            <div className="d-f f-c firstContainer">
              <div className="getOlText" style={{ color: "#4D4D4D" }}>
                We get logistics companies{" "}
                {width < 1023 && (
                  <span
                    style={{
                      fontFamily: "Poppins",
                      color: "#DC0639",
                      textTransform: "uppercase",
                    }}
                  >
                    Online
                  </span>
                )}
              </div>
              {width > 1023 && (
                <div>
                  <span
                    style={{
                      fontSize: "48px",
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      color: "#DC0639",
                      textTransform: "uppercase",
                    }}
                  >
                    Online
                  </span>
                </div>
              )}
              <div className="desc">
                {/* Speedbox provides white labeled technology solutions enabling
                door to door services for logistics companies. */}

                <span
                  style={{
                    fontSize: "24px",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                  }}
                >
                  White labeled technology solutions enabling door to door
                  services for logistics companies.
                </span>
              </div>
              <div className="buttonContainer">
                <Button
                  onClick={modelShow}
                  title="learn more"
                  textStyle={{
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                  style={{
                    boxShadow: "0px 10px 30px rgba(183, 50, 39, 0.2)",
                  }}
                />
              </div>
            </div>
          </Col>

          <Col
            style={{ zIndex: "33" }}
            className="p-0 "
            sm={12}
            xs={12}
            lg={6}
            md={6}
          >
            <div
              // className="rightSideImgContainer"
              className="rightSideContainer"
              // style={{ marginTop: "3em" }}
            >
              <video height={"497px"} width={"852px"} autoPlay muted loop>
                <source src="/images/logistics.mp4" type="video/mp4"></source>
              </video>
              {/* <img className="img-fluid" src={"/images/logistics.mp4"} /> */}
            </div>
          </Col>
        </Row>
      </section>
      <section
        className="sec2 wow fadeIn d-f f-c container-fluid"
        style={{ marginBottom: "100px", marginTop: "153px" }}
      >
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            {/* <div className="title">Our Products!</div> */}
            <div className="imgContainer">
              <video
                // height={"644px"}
                // width={"1521px"}
                controls
                autoPlay
                muted
                loop
                // style={{
                //   pointerEvents: "none",
                //   // height: "493px",
                //   overflow: "hidden",
                // }}
              >
                <source
                  src="/images/speedbox-video.mp4"
                  type="video/mp4"
                ></source>
                {/* <source src="movie.ogg" type="video/ogg"> */}
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        {/* <div></div> */}
      </section>
      <div
        className="title"
        style={{
          fontSize: "48px",
          color: "#4D4D4D",
          paddingTop: "100px",
          paddingBottom: "63px",
        }}
      >
        What we Offer!
      </div>
      <section id="prooduct" className="sec3 wow fadeIn d-f f-c">
        <div className="greyline"></div>

        {width < 1023 ? (
          <div className="mbcontainer " style={{ backgroundColor: "#FFF6F6" }}>
            <div className="titleContainer">
              <div className="title2">Web dashboard</div>
            </div>
            <div className="d-f f-c firstContainer">
              <div className="textControlTxt">
                Take control of your business!
              </div>
              <div className="desc">
                Manage & streamline your entire operations at the click of a
                button
              </div>
            </div>

            <div className="imgContainer mbsec3-swiper swiper-containe ">
              <div className="swiper-wrapper">
                {sec1ListArr.map((item, i) => {
                  return (
                    <ListItem
                      mobile={true}
                      gif={item.gif}
                      title={item.title}
                      desc={item.desc}
                      src2={item.src2}
                      desc2={item.desc2}
                    />
                  );
                })}
              </div>
              <div className="d-f j-c ">
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        ) : (
          <Row className="m-0 container" style={{ paddingBottom: "77px" }}>
            <Col className="p-0" lg={6}>
              <div className="titleContainer">
                <div className="title2">Web dashboard</div>
              </div>
              <div className="d-f f-c firstContainer">
                <div className="textControlTxt">
                  Take control of your business!
                </div>
                <div className="desc">
                  Manage & streamline your entire operations at the click of a
                  button
                </div>

                <div className="listContainer">
                  {sec1ListArr.map((item, i) => {
                    return (
                      <ListItem
                        onMouseEnter={(e) => sec1MouseEnter(e, i)}
                        selected={i == sec3HoverInd ? true : false}
                        title={item.title}
                        desc={item.desc}
                        desc2={item.desc2}
                      />
                    );
                  })}
                </div>

                <div className="buttonContainer">
                  <Button
                    onClick={modelShow}
                    title="Learn More"
                    style={{
                      background: "#3A3B3B",
                      color: "#fff",
                      borderRadius: "26px",
                      border: "0",
                      boxShadow: "0px 10px 30px rgba(183, 50, 39, 0.2)",
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col className="p-0 " lg={6}>
              <div className="imgContainer">
                <div className={`common ${sec3HoverInd === 0 ? "active" : ""}`}>
                  <img src={wd1} alt="User management" />
                </div>
                <div className={`common ${sec3HoverInd === 1 ? "active" : ""}`}>
                  <img src={wd2} alt="First and Last Mile Management" />
                </div>
                <div className={`common ${sec3HoverInd === 2 ? "active" : ""}`}>
                  <img src={wd3} alt="Reports and Analytics" />
                </div>
                <div className={`common ${sec3HoverInd === 3 ? "active" : ""}`}>
                  <img src={wd4} alt="Billing and Invoicing" />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </section>
      {/* {width > 1023 && <div className="divider"></div>} */}
      <section
        className="container-fluid"
        style={{ marginTop: "5em", marginBottom: "5em" }}
      >
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div
              style={{
                margin: "0px auto",
                maxHeight: "878px",
                maxWidth: "1505px",
              }}
            >
              <img
                src="/images/automation.jpg"
                style={{
                  margin: "0px auto",
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* {width > 1023 && <div className="divider"></div>} */}
      <section className="sec3 sec7 wow fadeIn d-f f-c">
        {width < 1023 ? (
          <div className="mbcontainer " style={{ backgroundColor: "#FFF6F6" }}>
            <div className="titleContainer">
              <div className="title2">User APP</div>
            </div>
            <div className="d-f f-c firstContainer">
              <div className="textControlTxt">Get your user on your app!</div>
              <div className="desc">
                Your own white labeled app, for your customers. Let your
                shippers download your app from iOS and Android
              </div>
            </div>

            <div className="imgContainer mbsec3-swiper swiper-containe f-c">
              <div className="swiper-wrapper">
                {sec4ListArr.map((item, i) => {
                  return (
                    <ListItem
                      mobile={true}
                      src={item.src}
                      title={item.title}
                      desc={item.desc}
                      src2={item.src2}
                      gif={item.gif}
                    />
                  );
                })}
              </div>
              <div className="d-f j-c ">
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        ) : (
          <Row className="m-0 container">
            <Col className="p-0" lg={6}>
              <div className="titleContainer">
                <div className="title2">User APP</div>
              </div>
              <div className="d-f f-c firstContainer">
                <div className="textControlTxt">Get your user on your app!</div>
                <div className="desc">
                  Your own white labeled app, for your customers. Let your
                  shippers download your app from iOS and Android
                </div>

                <div className="listContainer">
                  {sec4ListArr.map((item, i) => {
                    return (
                      <ListItem
                        onMouseEnter={(e) => sec3MouseEnter(e, i)}
                        selected={i == sec7HoverInd ? true : false}
                        title={item.title}
                        desc={item.desc}
                      />
                    );
                  })}
                </div>

                <div className="buttonContainer">
                  <Button
                    onClick={modelShow}
                    title="Learn More"
                    style={{
                      background: "#3A3B3B",
                      color: "#fff",
                      borderRadius: "26px",
                      border: "0",
                      boxShadow: "0px 10px 30px rgba(183, 50, 39, 0.2)",
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col className="p-0 " lg={6}>
              <div style={{ paddingLeft: "5em" }} className="imgContainer">
                <div className={`common ${sec7HoverInd === 0 ? "active" : ""}`}>
                  <img src={ua1} alt="Get quick rates" />
                </div>
                <div className={`common ${sec7HoverInd === 1 ? "active" : ""}`}>
                  <img src={ua2} alt="Place orders" />
                </div>
                <div className={`common ${sec7HoverInd === 2 ? "active" : ""}`}>
                  <img src={ua3} alt="Track shipments" />
                </div>
                <div className={`common ${sec7HoverInd === 3 ? "active" : ""}`}>
                  <img src={ua4} alt="Make Payments" />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </section>

      <section>
        <img
          src={increase}
          alt="Increase sales, operational efficiency & sales."
          style={{ width: "100%" }}
        />
      </section>

      {/* {width > 1023 && <div className="divider"></div>} */}
      <section className="sec5 wow fadeIn d-f f-c c-r">
        {width < 1023 ? (
          <div className="mbcontainer " style={{ backgroundColor: "#FFF6F6" }}>
            <div className="titleContainer2">
              <div className="title2">Squad App</div>
            </div>
            <div className="d-f f-c firstContainer">
              <div className="textControlTxt">
                Manage your pickups seamlessly!
              </div>
              <div className="desc">
                Seamlessly manage pickups and deliveries with Squad App
              </div>
            </div>

            <div className="imgContainer mbsec5-swiper swiper-containe ">
              <div className="swiper-wrapper">
                {sec2ListArr.map((item, i) => {
                  return (
                    <ListItem
                      mobile={true}
                      src={item.src}
                      title={item.title}
                      desc={item.desc}
                      desc2={item.desc2}
                      desc3={item.desc3}
                      desc4={item.desc4}
                      src2={item.src2}
                      gif={item.gif}
                    />
                  );
                })}
              </div>
              <div className="d-f j-c ">
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        ) : (
          <Row className="m-0 container">
            <Col className="p-0 col1" lg={6}>
              <div className="titleContainer">
                <div className="title2">Squad App</div>
              </div>
              <div className="d-f f-c firstContainer">
                <div className="textControlTxt">
                  Manage your pickups seamlessly!
                </div>
                <div className="desc">
                  Seamlessly manage pickups and deliveries with the Squad App
                </div>

                <div className="listContainer">
                  {sec2ListArr.map((item, i) => {
                    return (
                      <ListItem
                        right={true}
                        onMouseEnter={(e) => sec2MouseEnter(e, i)}
                        selected={i == sec5HoverInd ? true : false}
                        title={item.title}
                        desc={item.desc}
                        desc2={item.desc2}
                        desc3={item.desc3}
                        desc4={item.desc4}
                      />
                    );
                  })}
                </div>

                <div className="buttonContainer">
                  <Button
                    onClick={modelShow}
                    title="Learn More"
                    style={{
                      background: "#3A3B3B",
                      color: "#fff",
                      borderRadius: "26px",
                      border: "0",
                      boxShadow: "0px 10px 30px rgba(183, 50, 39, 0.2)",
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col className="p-0 " lg={6}>
              <div className="imgContainer">
                <div className={`common ${sec5HoverInd === 0 ? "active" : ""}`}>
                  <img src={sa1} alt="Route optimization" />
                </div>
                <div className={`common ${sec5HoverInd === 1 ? "active" : ""}`}>
                  <img src={sa2} alt="Geolocation" />
                </div>
                <div className={`common ${sec5HoverInd === 2 ? "active" : ""}`}>
                  <img src={sa3} alt="Driver mapping" />
                </div>
                <div className={`common ${sec5HoverInd === 3 ? "active" : ""}`}>
                  <img
                    src={sa4}
                    alt="Save 30% of your fuel costs and delivery time"
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </section>

      <section>
        <img
          src={decrease}
          alt="Reduce manpower, operational costs & reduce leakages."
          style={{ width: "100%" }}
        />
      </section>

      {/* {width > 1023 && <div className="divider"></div>} */}
      <section className="sec8 d-f wow fadeIn f-c c-r">
        {width < 1023 ? (
          <div className="mbcontainer " style={{ backgroundColor: "#FFF6F6" }}>
            <div className="titleContainer2">
              <div className="title2">Admin APP</div>
            </div>
            <div className="d-f f-c firstContainer">
              <div className="textControlTxt">
                Take control of your warehouse now!
              </div>
              <div className="desc">
                Keep a track of shipments coming in and out of your warehouse
              </div>
            </div>

            <div className="imgContainer mbsec8-swiper swiper-containe f-c">
              <div className="swiper-wrapper">
                {sec3ListArr.map((item, i) => {
                  return (
                    <ListItem
                      mobile={true}
                      src={item.src}
                      title={item.title}
                      desc={item.desc}
                      src2={item.src2}
                      gif={item.gif}
                    />
                  );
                })}
              </div>
              <div className="d-f j-c ">
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        ) : (
          <Row className="m-0 container">
            <Col className="p-0 col1" lg={6}>
              <div className="titleContainer">
                <div className="title2">Admin App</div>
              </div>
              <div className="d-f f-c firstContainer">
                <div className="textControlTxt">
                  Take control of your warehouse now!
                </div>
                <div className="desc">
                  Keep a track of shipments coming in and out of your warehouse
                </div>

                <div className="listContainer">
                  {sec3ListArr.map((item, i) => {
                    return (
                      <ListItem
                        right={true}
                        onMouseEnter={(e) => sec8MouseEnter(e, i)}
                        selected={i == sec8HoverInd ? true : false}
                        title={item.title}
                        desc={item.desc}
                      />
                    );
                  })}
                </div>

                <div className="buttonContainer">
                  <Button
                    onClick={modelShow}
                    title="Learn More"
                    style={{
                      background: "#3A3B3B",
                      color: "#fff",
                      borderRadius: "26px",
                      border: "0",
                      boxShadow: "0px 10px 30px rgba(183, 50, 39, 0.2)",
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col className="p-0 " lg={6}>
              <div className="imgContainer">
                {/* <div className={`common ${sec8HoverInd === 0 ? "active" : ""}`}>
                  <video ref={videoRef12} key="5" muted autoPlay loop>
                    <source src={admin1} alt="img" type="video/webm"></source>
                  </video>
                </div> */}
                {/* <div className={`common ${sec8HoverInd === 1 ? 'active' : ''}`}>
									<video ref={videoRef13} key='7' muted autoPlay loop>
										<source src={admin2} alt='img' type='video/webm'></source>
									</video>
								</div> */}
                <div className={`common ${sec8HoverInd === 0 ? "active" : ""}`}>
                  <img src={aa1} alt="In-scan & Out-Scan Shipments" />
                </div>
                <div className={`common ${sec8HoverInd === 1 ? "active" : ""}`}>
                  <img src={aa2} alt="Create carrier wise manifest reports" />
                </div>
                <div className={`common ${sec8HoverInd === 2 ? "active" : ""}`}>
                  <img src={aa3} alt="Edit & Update Shipments" />
                </div>
                <div className={`common ${sec8HoverInd === 3 ? "active" : ""}`}>
                  <img
                    src={aa4}
                    alt="Generate carrier, own and masked labels"
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </section>

      {/* Own your data section */}
      <section
        style={{
          // background:
          //   "linear-gradient(90.04deg, #6A0019 0.04%, #A20000 65.51%)",
          background: "#6A0019",
          color: "white",
          // marginBottom: "280px",
        }}
      >
        <div
          className="container-fluid"
          style={{ padding: "60px 0px", fontFamily: "Poppins" }}
        >
          <Row>
            {/* <Col lg={2} md={2}></Col> */}
            <Col
              lg={7}
              md={7}
              style={{ paddingLeft: "20em", paddingTop: "50px" }}
            >
              <h1
                style={{
                  fontSize: "48px",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  lineHeight: "100%",
                  // paddingTop: '50px'
                }}
              >
                Own your data
              </h1>
              <p style={{ fontSize: "36px" }}>
                Speedbox platform empowers you to own and manage your data with
                transparency and control.
              </p>
            </Col>
            <Col lg={4} md={4}>
              <img src="/images/own-your-data.png" />
            </Col>
          </Row>
        </div>
      </section>

      {/** Labelling and invoices section start **/}
      <div className="labelInvoice">
        <p>
          Labelling and invoices in{" "}
          <span style={{ color: "#EB0000" }}>your control</span>
        </p>
        {/* <p style={{ color: "#EB0000" }}>your control</p> */}
        <br />
      </div>
      <section className="sec10 d-f wow fadeIn f-c">
        <div className="imgContainer sec10-swiper swiper-container">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <img className="img-fluid" src={Sec10Img} />
            </div>
            <div className="swiper-slide">
              <img className="img-fluid" src={Sec10Img2} />
            </div>
            <div className="swiper-slide">
              <img className="img-fluid" src={Sec10Img3} />
            </div>
          </div>
          <div className="d-f j-c">
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
      {/** Labelling and invoices section end **/}

      {/* Footer section */}
      <section className="sec12 wow fadeIn footer">
        <div className="container">
          <div className="logoContainer">
            <img src={logo} className="img-fluid" alt="Speedbox" />
          </div>
          <p
            style={{
              color: "#202020",
              fontFamily: "Poppins",
              fontSize: "48px",
              lineHeight: "54px",
              fontWeight: "400",
              marginTop: "60px",
              marginBottom: "33px",
            }}
          >
            Get in touch with us
          </p>
        </div>
        <div
          className="container"
          style={{ color: "#5E6282", fontFamily: "Poppins" }}
        >
          <div className="row">
            <div className="col-lg-6">
              <h1
                style={{
                  fontSize: "32px",
                  lineHeight: "54px",
                  fontWeight: "600",
                }}
              >
                Enquiries
              </h1>
              <p
                style={{
                  fontSize: "1.5em",
                  lineHeight: "54px",
                  fontWeight: "500",
                }}
              >
                <img src={emailicon} alt="enquiries email" />
                <a href="mailto: hello@speedboxapp.com">
                  hello@speedboxapp.com
                </a>
              </p>
            </div>
            <div className="col-lg-6">
              <h1
                style={{
                  fontSize: "32px",
                  lineHeight: "54px",
                  fontWeight: "600",
                }}
              >
                Careers
              </h1>
              <p
                style={{
                  fontSize: "1.5em",
                  lineHeight: "54px",
                  fontWeight: "500",
                }}
              >
                <img src={emailicon} alt="careers email" />
                <a href="mailto: careers@speedboxapp.com">
                  careers@speedboxapp.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="container socialLink" style={{ marginTop: "4em" }}>
          <Row>
            <div className="col-lg-12 col-md-12 col-xs-6">
              <img
                src={linkedin}
                className="img-fluid"
                // width="100"
                // height="100"
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/speedbox",
                    "_blank"
                  );
                }}
                // className="icon"
              />
              <img
                src={fb}
                className="img-fluid"
                // width="100"
                // height="100"
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/speedboxlogistics/",
                    "_blank"
                  );
                }}
                // className="icon"
              />
              <img
                src={instagram}
                className="img-fluid"
                // width="100"
                // height="100"
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/speedboxlogistics/",
                    "_blank"
                  );
                }}
                // className="icon"
              />
              <img
                src={twitter}
                className="img-fluid"
                // width="100"
                // height="100"
                onClick={() => {
                  window.open("https://twitter.com/SpeedboxMaster", "_blank");
                }}
                // className="icon"
              />
            </div>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col lg={12} md={12} xs={12}>
              <div
                className="tncBlock"
                style={{ fontSize: "16px", font: "Poppins" }}
              >
                <a href="#">Terms and Condtions</a>
                <span style={{ margin: "0 1.5em" }}>|</span>
                <a href="#">Privacy Policy</a>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <ModelForm show={isShown} hide={() => setShown(false)} />
    </>
  );
};

export default App;
