import React from 'react'

import './style.less'

const InputText = (props) => {
	return (
		<div className='user-input-wrp'>
			<input
				type={props.type ? props.type : 'text'}
				maxLength={props.limit ? 100 : null}
				className='inputText'
				value={props.value}
				onChange={props.onChange}
				required
			/>
			<span className='floating-label'>{props.title}</span>
		</div>
	)
}

export default React.memo(InputText)
