import React from "react";
import "./style.less";
import loader from "../../images/loader.gif";
const Button = (props) => {
  //console.log("==>", props.fournotfonund);
  return (
    <div
      onClick={props.onClick}
      style={
        props.loading
          ? { pointerEvents: "none", cursor: "none" }
          : props.style
          ? props.style
          : null
      }
      className={
        props.fournotfonund
          ? `btn effect-1 fournotbtn ${props.className}`
          : `btn effect-1 ${props.className}`
      }
    >
      {props.loading && (
        <img src={loader} height="30" width="30" className="img-fluid" />
      )}

      <span style={props.textStyle || null}>{props.title}</span>
    </div>
  );
};

export default React.memo(Button);
